import React from 'react';
import './UnderConstruction.css';
import ConstructionDuck from '../../img/tokencipher_a_rubber_duck_doing_construction_work_1420093e-448e-40e5-bcc9-65dc8d950414.webp';
import AnimatedBubbleBackground from '../AnimatedBubbleBackground/AnimatedBubbleBackground';

class UnderConstruction extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {  
    return (
      <>
        <div className="container">
          <title>Version 1.0 Development Announcement</title>
          <h1>Under Construction</h1>
          <div className="logo">DUBARUB</div>
          <p> v1.0 is currently in development...</p>
          <img 
            className="frame-caution"
            src={ConstructionDuck}
            alt="rubber duck at construction site in safety gear" />
          <AnimatedBubbleBackground />
        </div>
      </>
    ); 
  }
}

export default UnderConstruction;