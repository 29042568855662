import React from 'react';
import UnderConstruction from '../UnderConstruction/UnderConstruction';

class App extends React.Component {
  render() {
    return (
      <>
        <UnderConstruction />
      </>
    )
  }
}

export default App;